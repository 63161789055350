<template>
  <div class="container-fluid">
    <NavBarVue />
    <div class="bg">
      <video autoplay muted loop>
        <source src="../assets/RJ_Digital1.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="col51-bg">
      <div class="col51" id="about_us">
        <div class="underDiv">
          <img src="../assets/redjinni.png" alt="" />
        </div>
        <div class="red">
          <h2 class="since"><span>SINCE 2014</span></h2>
          <h2 class="since" style="margin-top: 10px">
            Good is the Enemy of Great and we don't settle for Good things. We
            create only Great opportunities.
          </h2>
          <div class="line">
            <div class="redline"></div>
            <p class="med">
              Our Team Consists of Big Picture thinkers, Crafty Creators,
              Distinguished Developers, and Your Vision as the Canvas to Create
              an Aesthetic Piece of Art.
            </p>
          </div>
          <p class="small">
            With Actively Taken Feedback & by Leveraging Smart Frameworks and
            data-driven insight with our Finest Services, We Provide our Clients
            Incredibly Satisfying Work to Stand out from Crowd.
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- About Infinity Brains Section  -->

  <AboutInfinityBrainsVue />

  <div id="service_move">
    <h2 class="offer">THE <span> [SERVICES] </span> WE'RE OFFERING</h2>
  </div>
  <!-- Gallery -->
  <div class="row card-container">
    <div
      class="col-lg-4 col-md-12 mb-4 mb-lg-0 d-flex align-items-center justify-content-center flex-column"
    >
      <div class="card-width">
        <div
          class="d-flex align-items-center justify-content-center flex-column font-xsssss-important card-body"
        >
          <img style="width: 30vh" src="../assets/img1.png" alt="pic" />
          <h2 class="" style="margin: 5% 0%; font-size: 2rem">
            Web development & UI/UX
          </h2>
          <h3
            style="font-size: 1.5rem; align-items: center; text-align: center"
          >
            We develop website that User adore & interface that is and we
            understand what it takes to build a brand from the design
            perspective.
          </h3>
        </div>
      </div>

      <div class="card-width">
        <div
          class="d-flex align-items-center justify-content-center flex-column font-xsssss-important card-body"
        >
          <img style="width: 30vh" src="../assets/img2.png" alt="pic" />
          <h2 class="" style="margin: 5% 0%; font-size: 2rem">
            Marketing Strategey
          </h2>
          <h3
            style="font-size: 1.5rem; align-items: center; text-align: center"
          >
            Our Significant Strategy will benefit your company to achieve.our
            ultimate goal of a marketing strategy is to communicate a
            sustainable competitive advantages.
          </h3>
        </div>
      </div>
    </div>

    <div
      class="col-lg-4 mb-4 mb-lg-0 d-flex align-items-center justify-content-center flex-column"
    >
      <div class="card-width">
        <div
          class="d-flex align-items-center justify-content-center flex-column font-xsssss-important card-body"
        >
          <img style="width: 30vh" src="../assets/img3.png" alt="pic" />
          <h2 class="" style="margin: 5% 0%; font-size: 2rem">SEO</h2>
          <h3
            style="font-size: 1.5rem; align-items: center; text-align: center"
          >
            SEO plays a crucial role if your business has a website. SEO stands
            for the practice of increasing the quantity and quality of traffic
            to your website through organic search engine results.
          </h3>
        </div>
      </div>
      <div class="card-width">
        <div
          class="d-flex align-items-center justify-content-center flex-column font-xsssss-important card-body"
        >
          <img style="width: 30vh" src="../assets/img4.png" alt="pic" />
          <h2 class="" style="margin: 5% 0%; font-size: 2rem">SMM</h2>
          <h3
            style="font-size: 1.5rem; align-items: center; text-align: center"
          >
            we have an extraordinary range of avenues to connect with target
            audiences from content platforms and social sites to microblogging
            services.
          </h3>
        </div>
      </div>
      <div class="card-width">
        <div
          class="d-flex align-items-center justify-content-center flex-column font-xsssss-important card-body"
        >
          <img style="width: 30vh" src="../assets/img5.png" alt="pic" />
          <h2 class="" style="margin: 5% 0%; font-size: 2rem">
            Brand & Marketing
          </h2>
          <h3
            style="font-size: 1.5rem; align-items: center; text-align: center"
          >
            We build brand that customers indulge for and also We establishing
            and growing a relationship between a brand and consumers.
          </h3>
        </div>
      </div>
    </div>

    <div
      class="col-lg-4 mb-4 mb-lg-0 d-flex align-items-center justify-content-center flex-column"
    >
      <div class="card-width">
        <div
          class="d-flex align-items-center justify-content-center flex-column font-xsssss-important card-body"
        >
          <img style="width: 30vh" src="../assets/img6.png" alt="pic" />
          <h2 class="" style="margin: 5% 0%; font-size: 2rem">
            CONTENT MARKETING & PUBLIC RELATION
          </h2>
          <h3
            style="font-size: 1.5rem; align-items: center; text-align: center"
          >
            Content is a king and we are a king maker and also we build and
            maintain relationships with the public which is very important
            factor for us.
          </h3>
        </div>
      </div>
      <div class="card-width">
        <div
          class="d-flex align-items-center justify-content-center flex-column font-xsssss-important card-body"
        >
          <img style="width: 30vh" src="../assets/img7.png" alt="pic" />
          <h2 class="" style="margin: 5% 0%; font-size: 2rem">
            CREATIVE & WRITING
          </h2>
          <h3
            style="font-size: 1.5rem; align-items: center; text-align: center"
          >
            Creatives on latest events in monetary with effective writing.We do
            creatively and attractive writing for targetting to audiences.
          </h3>
        </div>
      </div>
    </div>
  </div>

  <div class="projects-bg">
    <div class="project" id="our-projects-section">
      <h3 class="h3">OUR PROJECTS</h3>
      <div class="button">
        <div class="inside-button">
          <h2 class="h2">Our Ongoing Projects</h2>
          <p class="para">
            By Leveraging Smart Frameworks and Data-driven Insights with our
            Finest services. We have created identities that Stand-out from
            Crowd.
          </p>
        </div>
        <button class="view">VIEW ALL PROJECT</button>
      </div>
      <div class="cont">
        <div class="box5">
          <img src="../assets/restro.png" alt="pic" class="image" />
          <h2>Restro World</h2>
        </div>
        <div class="box5">
          <img src="../assets/red.png" alt="pic" class="image-redprix" />
          <h2>REDPRIX</h2>
        </div>
        <div class="box5">
          <img src="../assets/hrms.png" alt="pic" class="image-hrms" />
          <h2>HRMS</h2>
        </div>
        <div class="box5">
          <img src="../assets/gj.png" alt="pic" class="image-gj" />
          <h2>GARBA JOCKEY</h2>
        </div>
      </div>
    </div>
  </div>

  <div class="client" id="client-reviews">
    <h2 class="what">What Our <span>Clients</span> Say.</h2>
    <div class="say">
      <div class="box6 mt-5">
        <div class="bordertop">
          <img src="../assets/contact.png" alt="" class="cont-logo" />
        </div>
        <div class="review-div">
          <img src="../assets/rate.png" alt="logo" class="rate" />
          <h3>
            The concept of Digital Marketing with each aspect explained in a
            very simple language, which helped me to understand my requirements
            better.<br />
            My brand has increased our social media presence to a wide range of
            customers.
          </h3>
          <hr />
          <h3 class="customer">Amit Singh</h3>
        </div>
      </div>
      <div class="box6">
        <div class="bordertop">
          <img src="../assets/contact.png" alt="" class="cont-logo" />
        </div>
        <div class="review-div">
          <img src="../assets/rate.png" alt="logo" class="rate" />
          <h3>
            From their marketing strategies and creatives in every situation
            helped our company to deliberate our brand more precisely. Our
            company has seen tremendous growth in our LinkedIn presence.
          </h3>
          <hr />
          <h3 class="customer">Vivek Kasodariya</h3>
        </div>
      </div>
      <div class="box6">
        <div class="bordertop">
          <img src="../assets/contact.png" alt="" class="cont-logo" />
        </div>
        <div class="review-div">
          <img src="../assets/rate.png" alt="logo" class="rate" />
          <h3>
            Their UI/UX feels so smooth, it's very attractive and eye-soothing.
            The blogs and assertive language they use are very informational and
            helped our website to rake upper on Google. Keyword research is
            top-notch no doubt about that!
          </h3>
          <hr />
          <h3 class="customer">Pranjal Ramchandani</h3>
        </div>
      </div>
    </div>
  </div>

  <FooterVue />
</template>
<script>
import FooterVue from "./Footer/Footer.vue";
import NavBarVue from "./NavBar/NavBar.vue";

import AboutInfinityBrainsVue from "./AboutInfinityBrains.vue";

export default {
  name: "MenuBar",
  components: {
    NavBarVue,
    FooterVue,

    AboutInfinityBrainsVue,
  },
};
</script>
<style>
</style>