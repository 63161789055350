<template >
  <NavBarVue />
  <div class="contact-us-bg">
    <div class="contact-us">
      <div class="col5">
        <h1 class="reach">Reach us</h1>
        <h3 class="ads">
          There's never been a better time to be in adverting.
        </h3>
        <div class="line2">
          <div class="col25-1">
            <h2 class="like">What would you like to talk about today?</h2>
          </div>
          <div class="col25-2">
            <form class="flex">
              <select name="" id="" class="text" v-model="selectedReason">
                <option value="" disabled selected>SELECT ONE OPTION</option>
                <option value="markting">Digital Marketing</option>
                <option value="consultancy">Consultancy</option>
              </select>
              <input
                type="text"
                class="text"
                placeholder="NAME"
                v-model="name"
              />
              <input
                type="email"
                class="text"
                placeholder="EMAIL"
                v-model="email"
              />
              <input
                type="telephone"
                class="text"
                placeholder="PHONE NUMBER"
                v-model="number"
              />
              <textarea
                cols="20"
                rows="5"
                class="text1"
                placeholder="MESSAGE"
                v-model="message"
              ></textarea>
              <button class="submit" @click.prevent="sendMessage">
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="col5-img">
        <img src="../assets/phone.png" alt="" />
        <h2 class="free">
          Schedule a free consultation with our genius team and get ideas which
          makes your brand glow like a star. It's much easier to double your
          business by doubling your conversion rate then by doubling your
          traffic
        </h2>
      </div>
    </div>
  </div>
  <div class="location">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.1358602750643!2d72.75752791424746!3d21.14699088910896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04c2ce45738cd%3A0x2995c9ae54073ee1!2sRedjinni.com!5e0!3m2!1sen!2sin!4v1665204002315!5m2!1sen!2sin"
      width="100%"
      height="500px"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
  <FooterVue />
</template>
<script>
import axios from "axios";
import NavBarVue from "./NavBar/NavBar.vue";
import FooterVue from "./Footer/Footer.vue";
import Swal from "sweetalert2";

export default {
  name: "ContactUs",
  data() {
    return {
      selectedReason: "",
      name: "",
      email: "",
      number: "",
      message: "",
      apiUrl: "https://api.infinitybrains.com/api/contactdetails",
    };
  },
  methods: {
    sendMessage() {
      axios
        .post(this.apiUrl, {
          fullname: this.name,
          email: this.email,
          mobile: this.number,
          messages: this.message,
        })
        .then(() => {
          // console.log("Response of Contact: ", res);
          this.name = "";
          this.email = "";
          this.number = "";
          this.message = "";
          this.selectedReason = "";
          Swal.fire({
            title: "Message Received!",
            text: "Message sended successfully!",
            icon: "success",
          });
        })
        .catch(() => {
          // console.log("Error in Contact: ", err);
          Swal.fire({
            title: "Something Went Wrong!!",
            text: `Message not sended !!`,
            icon: "error",
          });
        });
    },
  },
  components: {
    FooterVue,
    NavBarVue,
  },
};
</script>
<style>
.location {
  margin: 30px 5px;
}
</style>