<template>
  <NavBarVue />
  <div class="services-container">
    <div class="services-section-1">
      <div class="services-section-img-div-1">
        <img src="./assets/Group_3297.png" alt="service-1-img" />
      </div>
      <div class="service-section-desc-1">
        <h3>01</h3>
        <h3>SEO(SEARCH ENGINE MARKETING)</h3>
        <h4>
          SEO plays a crucial role if your business has a website; if not, then
          it’s essential too, because of its organic attraction power. We’ll
          help you optimize your website for better visibility and better
          ranking in the SERPs.
        </h4>
        <h4>
          Searching for keywords and finding loopholes is time consuming and
          often fruitless, we’ve got experts for researching loopholes, so you
          don’t waste your time and focus on the right things.
        </h4>
      </div>
    </div>

    <div class="services-section-bg">
      <div class="services-section-2">
        <div class="services-section-img-div-2">
          <img src="./assets/Group_3299.png" alt="service-2-img" />
        </div>
        <div class="service-section-desc-2">
          <h3>02</h3>
          <h3>SMM(SOCIAL MEDIA MARKETING)</h3>
          <h4>
            Have you ever thought of reaching out to 4.61 billion individuals,
            it's the number of individual users on social media. Yes, you can
            reach out to that number if you have the right strategy and tactics
            in place.
          </h4>
          <h4>
            We create effective and engaging content to grab the attention of
            the users so the name of your brand will always have a presence in
            their minds.
          </h4>
        </div>
      </div>
    </div>

    <div class="services-section-3">
      <div class="services-section-img-div-3">
        <img src="./assets/Group_3312.png" alt="service-1-img" />
      </div>
      <div class="service-section-desc-3">
        <h3>03</h3>
        <h3>BRAND MARKETING</h3>
        <h4>
          Create an enduring perception in the minds of your customers and
          distinguish yourself from your competitors. It’s focusing on
          Permanence, not just Performance. We Build a brand that customers
          indulge in and adore.
        </h4>
      </div>
    </div>

    <div class="services-section-bg">
      <div class="services-section-4">
        <div class="services-section-img-div-4">
          <img src="./assets/Group_3313.png" alt="service-1-img" />
        </div>
        <div class="service-section-desc-4">
          <h3>04</h3>
          <h3>CONTENT MARKETING & PUBLIC RELATION</h3>
          <h4>
            Content is the King and we’re Kingmaker, Content Marketing is 3X
            more effective than ordinary. Make your content valuable and
            attractive to create an enduring perception in the minds of your
            customers.
          </h4>
          <h4>
            Public Relation is Opportunistic, Delivering the right message to
            the right people in the right place, creates a stronger brand
            reputation.
          </h4>
        </div>
      </div>
    </div>

    <div class="services-section-5">
      <div class="services-section-img-div-5">
        <img src="./assets/Group_4143.png" alt="service-1-img" />
      </div>
      <div class="service-section-desc-5">
        <h3>05</h3>
        <h3>CREATIVE & WRITING</h3>
        <h4>
          Creatives can deliver important and meaningful messages without
          speaking or showing a word. Get creatives for every occasion and
          improve the organic audience.
        </h4>
        <h4>
          Effective writing is critical in turning your site visitors into
          satisfied customers.
        </h4>
      </div>
    </div>

    <div class="services-section-bg">
      <div class="services-section-6">
        <div class="services-section-img-div-6">
          <img src="./assets/Group_3315.png" alt="service-1-img" />
        </div>
        <div class="service-section-desc-6">
          <h3>06</h3>
          <h3>WEB DEVLOPMENT & UI/UX</h3>
          <h4>
            A website helps to establish your image by letting the audience know
            who you are and what you represent.
          </h4>
          <h4>
            UI/UX is of crucial importance because it facilitates the workflows
            of different personas, creates meaningful user experiences,
            maximizes customers' lifecycle flow, adds up brand image, and stays
            relevant to real customers over a period of time.
          </h4>
        </div>
      </div>
    </div>

    <div class="services-section-7">
      <div class="services-section-img-div-7">
        <img src="./assets/Group_3316.png" alt="service-1-img" />
      </div>
      <div class="service-section-desc-7">
        <h3>07</h3>
        <h3>MARKETING STRATEGY</h3>
        <h4>
          Through the right strategy and creative work, you can engender trust
          and admiration among your prospective customers. Your company can
          increase market share and facilitate enhanced brand value, competitive
          advantages, higher sales, and elevated profits.
        </h4>
        <h4>
          We create a comprehensive marketing strategy to ensure you're
          targeting the right people, with relevant content that appeals to
          them.
        </h4>
      </div>
    </div>
  </div>
  <FooterVue />
</template>

<script>
import "./style/ServicesPage.css";
import FooterVue from "../Footer/Footer.vue";
import NavBarVue from "../NavBar/NavBar.vue";

export default {
  name: "ServicesPage",
  components: {
    NavBarVue,
    FooterVue,
  },
};
</script>

<style></style>


  
