<template>
  <NavBarVue />
  <div class="payment-container">
    <div class="payment-heading">
      <h3>Payment Method</h3>
    </div>
    <div class="payment-brand-div">
      <div class="payment-brand-img-div">
        <img src="./assets/hrms.png" alt="brand-img" />
        <h3>HRMS</h3>
      </div>
      <div class="payment-brand-text">
        <h3 class="payment-brand-heading">HRMS</h3>
        <div class="payment-brand-desc">
          <div class="left-red-line"></div>
          <p class="payment-brand-desc">
            Our Team Consist of Big Picture thinkers, Crafty Creators,
            Distinguished Developers, and Your Vision as the Canvas to Create an
            Aesthetic Piece of Art.
          </p>
        </div>
      </div>
    </div>

    <div class="payment-heading">
      <h3>Payment Method</h3>
    </div>

    <div class="payment-billing-section">
      <div class="payment-form">
        <h3>PERSONAL DETAILS:</h3>
        <div class="form-row-1">
          <div class="form-input-filed">
            <label for="first_name">First Name</label>
            <input
              type="text"
              id="first_name"
              placeholder="First Name"
              v-model="first_name"
            />
          </div>
          <div class="form-input-filed">
            <label for="last_name">Last Name</label>
            <input
              type="text"
              id="last_name"
              placeholder="Last Name"
              v-model="last_name"
            />
          </div>
        </div>
        <div class="form-row">
          <label for="email">Email Address</label>
          <input
            type="text"
            id="email"
            placeholder="Email Address"
            v-model="email"
          />
        </div>
        <div class="form-row">
          <label for="number">Mobile Number</label>
          <input
            type="text"
            id="number"
            placeholder="Mobile Number"
            v-model="mobile_number"
          />
        </div>
        <div class="form-row">
          <label for="address">Address</label>
          <input
            type="text"
            id="address"
            placeholder="Address"
            v-model="address"
          />
        </div>
        <div class="form-row-1">
          <div class="form-input-filed">
            <label for="country">Country</label>
            <!-- <input type="text" id="country" placeholder="Country" /> -->
            <select v-model="country" @change="loadStates" id="country">
              <option disabled value="">Select Country</option>
              <option
                v-for="country in countries"
                :key="country.id"
                :value="country.id"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
          <div class="form-input-filed">
            <label for="state">State</label>
            <!-- <input type="text" id="state" placeholder="State" /> -->
            <select v-model="state" @change="loadCities" id="state">
              <option disabled value="">Select State</option>
              <option v-for="state in states" :key="state.id" :value="state.id">
                {{ state.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row-1">
          <div class="form-input-filed">
            <label for="city">City</label>
            <!-- <input type="text" id="city" placeholder="City" /> -->
            <select v-model="city" id="city">
              <option disabled value="">Select City</option>
              <option v-for="city in cities" :key="city.id" :value="city.id">
                {{ city.name }}
              </option>
            </select>
          </div>
          <div class="form-input-filed">
            <label for="pincode">Pincode</label>
            <input
              type="text"
              id="pincode"
              placeholder="954 321"
              v-model="pincode"
            />
          </div>
        </div>
      </div>
      <div class="center-line-div"></div>
      <div class="payment-billing">
        <h3>BILLING INFORMATION</h3>

        <div class="billing-info">
          <div class="billing-info-heading">
            <h3>Product Name</h3>
            <h3>Rate(₹)</h3>
          </div>

          <div class="price-row-billing" style="margin: 1.5rem 0">
            <h3 id="price-tag">HRMS</h3>
            <h3>00.00</h3>
          </div>

          <div class="price-row-billing">
            <h3 id="price-tag">GST:</h3>
          </div>

          <div class="price-row-billing">
            <h3 id="price-tag">C-GST(9%):</h3>
            <h3>00.00</h3>
          </div>

          <div class="price-row-billing" id="doted-line">
            <h3 id="price-tag">S-GST(9%):</h3>
            <h3>00.00</h3>
          </div>

          <div class=""></div>

          <div class="price-row-billing">
            <h3 id="price-tag">Sub Total:</h3>
            <h3>00.00</h3>
          </div>

          <div class="price-row-billing" id="doted-line">
            <h3 id="price-tag">Coupon Discount:</h3>
            <h3>00.00</h3>
          </div>

          <div class="price-row-billing">
            <h3 id="price-tag">Total Payment:</h3>
            <h3>00.00</h3>
          </div>

          <div class="billing-button-section">
            <button type="button" id="apply-btn">APPLY COUPON</button>
            <button type="button">PAY NOW</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterVue />
</template>

<script>
import "./style/PaymentPage.css";
import FooterVue from "../Footer/Footer.vue";
import NavBarVue from "../NavBar/NavBar.vue";

import axios from "axios";

export default {
  name: "PaymentPage",
  components: {
    NavBarVue,
    FooterVue,
  },
  data() {
    return {
      countries: [],
      states: [],
      cities: [],

      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      address: "",
      country: "101",
      state: "",
      city: "",
      pincode: "",
    };
  },
  methods: {
    async loadCountries() {
      try {
        let allCountries = [];
        let shouldContinue = true;
        let page = 1;

        while (shouldContinue) {
          const response = await axios.get(
            `https://api.garbajockey.com/api/countries?page=${page}`
          );
          // console.log("Response:", response); // Log the response

          const countries = response.data && response.data.data;

          if (!countries || countries.length === 0) {
            // No more countries to fetch, break the loop
            shouldContinue = false;
          } else {
            allCountries = [...allCountries, ...countries];
            page++;
          }
        }

        this.countries = allCountries;
        // console.log("All Countries:", this.countries); // Log the final list of countries
      } catch (error) {
        console.error("Error loading countries:", error);
      }
    },

    async loadStates() {
      try {
        const response = await axios.get(
          `https://api.garbajockey.com/api/states_of_country?country_id=${this.country}`
        );
        this.states = response.data.data; // Update states array
        // console.log("states = ", this.states);
        this.state = ""; // Reset state value
        this.city = ""; // Reset city value
      } catch (error) {
        console.error("Error loading states:", error);
      }
    },

    async loadCities() {
      try {
        const response = await axios.get(
          `https://api.garbajockey.com/api/cities_of_state?state_id=${this.state}`
        );
        this.cities = response.data.data; // Update cities array
        // console.log("states = ", this.cities);
        this.city = ""; // Reset city value
      } catch (error) {
        console.error("Error loading cities:", error);
      }
    },
  },
  mounted() {
    this.loadCountries();
    this.loadStates();
  },
};
</script>

<style ></style>