<template>
  <div>
    <NavBar />
    <div class="tutorial-container">
      <div class="tutorial-main-div">
        <div class="tutorial-header-bg">
          <div class="tutorial-header">
            <img
              src="./assets/header_bg.png"
              alt="header_bg"
              class="header-bg"
            />
            <div class="tutorial-details">
              <div class="tutorial-header-desc">
                <img src="./assets/ib_logo.png" alt="ib_logo" />
                <h3>LEARN AND GROW WITH OUR TUTORIALS</h3>
                <h4>
                  The header serves as the navigational backbone of the page. It
                  includes the website logo for brand recognition, a navigation
                  bar for easy access to key sections, and a search bar for
                  quick tutorial lookups.
                </h4>
              </div>
              <div class="tutorial-header-img-div">
                <img src="./assets/Group_44667.png" alt="header-img" />
              </div>
            </div>
          </div>
        </div>
        <div class="tutorial-heading">
          <h3>HOW REDJINNI WORKS?</h3>
          <h4>Step-By-Step Guides To Enhance Your Skills</h4>
        </div>
        <div class="tutorial-first-video-section">
          <!-- RJ WEB -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(web_rj)">
              <img src="./assets/rj_laptop.png" alt="video-img" />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(web_rj)">HOW DOES REDJINNI WEBSITE WORK?</h3>
              <h4 @click="redirect(web_rj)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(web_rj)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="tutorial-heading heading-2">
          <h3>WHAT IS INFINITYBRAINS ?</h3>
          <h4>Step-By-Step Guides To Enhance Your Skills</h4>
        </div>
        <div class="tutorial-second-video-section">
          <div class="tutorial-video-div-2" @click="redirect(video_one_hour)">
            <div class="video-img-div-2" @click="redirect(video_one_hour)">
              <img src="./assets/video-2-img.png" alt="video-2-img" />
            </div>
            <div class="video-desc-2" @click="redirect(video_one_hour)">
              <h3>
                Infinity Brains Product Showcase: How Our Apps and Web Platforms
                Work
              </h3>
              <h4>
                Welcome to Infinity Brains! In this comprehensive one-hour
                video, we showcase all of our company's innovative products
                through detailed screen recordings. You'll learn how our apps
                and websites work, making it easy for users to navigate and
                utilize our technology. Plus, we've sprinkled in some funny
                clips to keep things entertaining. Join us and discover the
                functionality and fun behind Infinity Brains' products!
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                id="second-video-btn"
                @click="redirect(video_one_hour)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="tutorial-heading heading-2">
          <h3>ALL PRODUCTS TUTORIALS</h3>
          <h4>Step-By-Step Guides To Enhance Your Skills</h4>
        </div>
        <div class="tutorial-first-video-section">
          <!-- IB WEB -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(web_ib)">
              <img src="./assets/ib_laptop.png" alt="video-img" />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(web_ib)">
                HOW DOES INFINITYBRAINS WEBSITE WORK?
              </h3>
              <h4 @click="redirect(web_ib)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(web_ib)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- IB APP  -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(app_ib)">
              <img
                src="./assets/ib_mobile.png"
                id="mobile-img"
                alt="video-img"
              />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(app_ib)">
                HOW DOES INFINITYBRAINS APPLICATION WORK?
              </h3>
              <h4 @click="redirect(app_ib)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(app_ib)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- DELIGHT WEB  -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(web_delight)">
              <img src="./assets/delight_laptop.png" alt="video-img" />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(web_delight)">
                HOW DOES DELIGHT WEBSITE WORK?
              </h3>
              <h4 @click="redirect(web_delight)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(web_delight)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- DELIGHT APP -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(app_delight)">
              <img
                src="./assets/delight_mobile.png"
                id="mobile-img"
                alt="video-img"
              />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(app_delight)">
                HOW DOES DELIGHT APPLICATION WORK?
              </h3>
              <h4 @click="redirect(app_delight)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(app_delight)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- HRMS WEB  -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(web_hrms)">
              <img src="./assets/hrms_laptop.png" alt="video-img" />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(web_hrms)">HOW DOES IBHRMS WEBSITE WORK?</h3>
              <h4 @click="redirect(web_hrms)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(web_hrms)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- HRMS APP -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(app_hrms)">
              <img
                src="./assets/hrms_mobile.png"
                id="mobile-img"
                alt="video-img"
              />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(app_hrms)">
                HOW DOES IBHRMS APPLICATION WORK?
              </h3>
              <h4 @click="redirect(app_hrms)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(app_hrms)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- RW WEB  -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(web_rw)">
              <img src="./assets/rw_laptop.png" alt="video-img" />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(web_rw)">
                HOW DOES RESTROWORLD WEBSITE WORK?
              </h3>
              <h4 @click="redirect(web_rw)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(web_rw)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- RW APP -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(app_rw)">
              <img
                src="./assets/rw_mobile.png"
                id="mobile-img"
                alt="video-img"
              />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(app_rw)">
                HOW DOES RESTROWORLD APPLICATION WORK?
              </h3>
              <h4 @click="redirect(app_rw)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(app_rw)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- RR APP -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(app_rr)">
              <img
                src="./assets/rr_mobile_1.png"
                id="mobile-img"
                alt="video-img"
              />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(app_rr)">
                HOW DOES RED REDTRO APPLICATION WORK?
              </h3>
              <h4 @click="redirect(app_rr)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(app_rr)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- SMK APP -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(app_smk)">
              <img
                src="./assets/smk_mobile_1.png"
                id="mobile-img"
                alt="video-img"
              />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(app_smk)">
                HOW DOES SHREE MAHALAKSHMI KRUPA APPLICATION WORK?
              </h3>
              <h4 @click="redirect(app_smk)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(app_smk)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- IBSF WEB  -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(web_ibsf)">
              <img src="./assets/ibsf_laptop.png" alt="video-img" />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(web_ibsf)">
                HOW DOES IB SALESFORCE WEBSITE WORK?
              </h3>
              <h4 @click="redirect(web_ibsf)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(web_ibsf)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- IBSF APP -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(app_ibsf)">
              <img
                src="./assets/ibsf_mobile.png"
                id="mobile-img"
                alt="video-img"
              />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(app_ibsf)">
                HOW DOES IB SALESFORCE APPLICATION WORK?
              </h3>
              <h4 @click="redirect(app_ibsf)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(app_ibsf)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- GJ WEB  -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(web_gj)">
              <img src="./assets/gj_laptop.png" alt="video-img" />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(web_gj)">
                HOW DOES GARBA JOCKEY WEBSITE WORK?
              </h3>
              <h4 @click="redirect(web_gj)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(web_gj)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- GJ APP -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(app_gj)">
              <img
                src="./assets/gj_mobile.png"
                id="mobile-img"
                alt="video-img"
              />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(app_gj)">
                HOW DOES GARBA JOCKEY APPLICATION WORK?
              </h3>
              <h4 @click="redirect(app_gj)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(app_gj)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- RP WEB  -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(web_rp)">
              <img src="./assets/rp_laptop.png" alt="video-img" />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(web_rp)">HOW DOES REDPRIX WEBSITE WORK?</h3>
              <h4 @click="redirect(web_rp)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(web_rp)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- RP APP -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(app_rp)">
              <img
                src="./assets/rp_mobile.png"
                id="mobile-img"
                alt="video-img"
              />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(app_rp)">
                HOW DOES REDPRIX APPLICATION WORK?
              </h3>
              <h4 @click="redirect(app_rp)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(app_rp)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- CP WEB -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(web_cp)">
              <img src="./assets/cp_laptop.png" alt="video-img" />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(web_cp)">
                HOW DOES CONSULTANT PANEL WEBSITE WORK?
              </h3>
              <h4 @click="redirect(web_cp)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(web_cp)"
              >
                <div class="inside-button_1">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="red-area-div"></div>

      <div class="last-logo-div">
        <img
          src="./assets/logo.jpg"
          alt="Logo Image"
          @click="this.$router.push('/')"
        />
      </div>
    </div>

    <FooterComp />
  </div>
</template>

<script>
import "./style/TutorialPage.css";

import NavBar from "../NavBar/NavBar.vue";
import FooterComp from "../Footer/Footer.vue";

import Swal from "sweetalert2";

export default {
  name: "TutorialPage",
  components: {
    NavBar,
    FooterComp,
  },
  data() {
    return {
      web_rj: "",

      app_ib: "",
      web_ib: "https://www.youtube.com/watch?v=bTN4DG8Hy7M",

      app_gj: "https://www.youtube.com/watch?v=jJIdM0jGs7o",
      web_gj: "https://www.youtube.com/watch?v=2J5rR5vcbLg",

      app_rp: "https://www.youtube.com/watch?v=-ITkvE28ils",
      web_rp: "https://www.youtube.com/watch?v=FHy0nbT2UKQ",

      app_rw: "https://www.youtube.com/watch?v=HlXlMpf_iwA",
      web_rw: "https://www.youtube.com/watch?v=CueW25f9LfU",

      app_hrms: "https://www.youtube.com/watch?v=zVT70QCWeKM",
      web_hrms: "https://www.youtube.com/watch?v=k3SN_O8KLJ4",

      web_cp: "",

      app_rr: "https://www.youtube.com/watch?v=uVvaoAyJuEc",
      app_smk: "",

      app_delight: "https://www.youtube.com/watch?v=NwkLxqsO3OM",
      web_delight: "https://www.youtube.com/watch?v=0ZQaOuTOiwo",

      app_ibsf: "https://www.youtube.com/watch?v=gnhNIOrkX6A",
      web_ibsf: "",

      video_one_hour: "https://youtu.be/fcODYjNaQ7s?si=TQfr6QfdZ3g2ZjyS",
    };
  },
  methods: {
    redirect(url) {
      if (url !== "") {
        window.location.href = url;
      } else {
        Swal.fire({
          title: "Video Uploading In Progress!!",
          text: "That video content will be uploaded soon!!",
          icon: "info",
        });
      }
    },
  },
};
</script>

<style scoped>
.last-logo-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;
  cursor: pointer;
}

.last-logo-div img {
  width: 12rem;
}

.red-area-div {
  width: 100%;
  height: 50px;
  margin-top: 24px;
  margin-bottom: 12px;
  background-color: #eb0e19;
}
</style>
