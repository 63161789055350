<template>
  <div class="menu-bar-bg">
    <div class="menubar">
      <router-link to="/" class="logo">
        <img src="../../assets/logo.jpg" alt="" />
      </router-link>
      <div class="menu">
        <ul>
          <li>
            <router-link to="/" style="text-decoration: none">HOME</router-link>
          </li>
          <li>
            <router-link to="/promotionPage" style="text-decoration: none"
              >PROMOTION</router-link
            >
          </li>
          <li>
            <router-link to="/aboutusPage" style="text-decoration: none"
              >ABOUT US</router-link
            >
          </li>
          <li>
            <router-link to="/servicesPage" style="text-decoration: none"
              >SERVICES</router-link
            >
          </li>
          <li>
            <router-link to="/IBTalks" style="text-decoration: none"
              >IB TALKS</router-link
            >
          </li>
          <li>
            <router-link to="/AllBlogs" style="text-decoration: none"
              >BLOGS</router-link
            >
          </li>
          <li>
            <router-link to="/tutorialPage" style="text-decoration: none"
              >TUTORIALS</router-link
            >
          </li>
          <li>
            <router-link to="/pricingpage" style="text-decoration: none"
              >PRICING</router-link
            >
          </li>
          <li>
            <router-link
              to="/contactUs"
              class="views"
              style="text-decoration: none"
              >CONTACT US</router-link
            >
          </li>
        </ul>
      </div>

      <div class="menu-icon" @click="openSideBar">
        <i class="fa-solid fa-bars"></i>
      </div>

      <div class="sidebar" :class="{ 'show-sidebar': showSidebar }">
        <div class="sidebar-heading">
          <h3>Menu</h3>
          <i class="fa-solid fa-xmark" @click="openSideBar"></i>
        </div>
        <hr />
        <ul>
          <li>
            <router-link to="/" style="text-decoration: none">HOME</router-link>
          </li>
          <li>
            <router-link to="/promotionPage" style="text-decoration: none"
              >PROMOTION</router-link
            >
          </li>
          <li>
            <router-link to="/aboutusPage" style="text-decoration: none"
              >ABOUT US</router-link
            >
          </li>
          <li>
            <router-link to="/servicesPage" style="text-decoration: none"
              >SERVICES</router-link
            >
          </li>
          <li>
            <router-link to="/IBTalks" style="text-decoration: none"
              >IB TALKS</router-link
            >
          </li>
          <li>
            <router-link to="/AllBlogs" style="text-decoration: none"
              >BLOGS</router-link
            >
          </li>
          <li>
            <router-link to="/tutorialPage" style="text-decoration: none"
              >TUTORIALS</router-link
            >
          </li>
          <li>
            <router-link to="/pricingpage" style="text-decoration: none"
              >PRICING</router-link
            >
          </li>
          <li>
            <router-link
              to="/contactUs"
              class="views-1"
              style="text-decoration: none"
              >CONTACT US</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import "./style/NavBar.css";

export default {
  name: "NavBar",
  data() {
    return {
      showSidebar: false,
    };
  },
  methods: {
    openSideBar() {
      this.showSidebar = !this.showSidebar;
      console.log("Function called !!!", this.showSidebar);
    },
  },
};
</script>

<style>
</style>