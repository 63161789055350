<template>
  <div class="footer-bg">
    <div class="footer">
      <div class="inside-footer">
        <div class="footerimg">
          <router-link to="/">
            <img src="../../assets/logo.jpg" alt="company logo" />
          </router-link>
          <h2>
            604, Luxuria Business Hub Nr VR Mall, Vesu, Surat, Gujrat 395007
          </h2>
          <div class="font">
            <i
              class="fa-brands fa-square-facebook"
              @click="redirectToFacebook"
            ></i>
            <i
              class="fa-brands fa-square-twitter"
              @click="redirectToTwitter"
            ></i>
            <i class="fa-brands fa-linkedin" @click="redirectToLinkedin"></i>
          </div>
        </div>
        <div class="info">
          <div class="about-info">
            <div class="company">
              <h2>Company</h2>
              <router-link
                to="/aboutusPage"
                class="link"
                style="text-decoration: none"
                >About Us</router-link
              >
              <router-link
                to="/servicesPage"
                class="link"
                style="text-decoration: none"
                >Service</router-link
              >
              <router-link to="/" class="link" style="text-decoration: none"
                >Our Mission</router-link
              >
            </div>
            <div class="company">
              <h2>Service</h2>
              <router-link
                to="/aboutusPage"
                class="link"
                style="text-decoration: none"
                >How We Work</router-link
              >
              <router-link
                to="/servicesPage"
                class="link"
                style="text-decoration: none"
                >Our Service</router-link
              >
              <router-link
                to="/contactus"
                class="link"
                style="text-decoration: none"
                >Contact Us</router-link
              >
            </div>
            <div class="company">
              <h2>Quick Link</h2>
              <router-link to="/" class="link" style="text-decoration: none"
                >Our Project</router-link
              >
              <router-link to="/" class="link" style="text-decoration: none"
                >Privacy Policy</router-link
              >
              <router-link
                to="/contactus"
                class="link"
                style="text-decoration: none"
                >Contact Us</router-link
              >
            </div>
          </div>
          <div class="last">
            <div class="buttons">
              <div class="inside-button-div">
                <div class="width" @click="this.$router.push('/contactus')">
                  <button class="us" @click="this.$router.push('/contactus')">
                    EMAIL US
                  </button>
                  <input
                    type="text"
                    placeholder="XYZ000@gmail.com"
                    @click="this.$router.push('/contactus')"
                  />
                  <button class="us" @click="this.$router.push('/contactus')">
                    SUBMIT
                  </button>
                </div>
                <h5>SUBSCRIBE FOR OFFERS AND BLOGPOSTS.</h5>
              </div>
              <div class="raise-ticket-div" @click="redirectRaiseTicket">
                <img
                  src="../../assets/icons8-ticket-50.svg"
                  alt="ticket-icon"
                />
                <h3>Raise a Ticket</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <h4>© {{ returnCurrentYear() }}. All rights reserved</h4>
      </div>
    </div>
  </div>
</template>

<script>
import "./style/Footer.css";

export default {
  name: "FooterComp",
  methods: {
    redirectRaiseTicket() {
      this.$router.push("/raiseTicket");
    },
    redirectToFacebook() {
      window.location.href = "https://www.facebook.com/";
    },
    redirectToTwitter() {
      window.location.href = "https://x.com/";
    },
    redirectToLinkedin() {
      window.location.href = "https://in.linkedin.com/";
    },
    returnCurrentYear() {
      const currentDate = new Date();
      // console.log("Current Date: ", currentDate.getFullYear());
      return currentDate.getFullYear();
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style></style>