<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import "./style/Allstyle.css";

export default {
  name: "App",
};
</script>

<style>
* {
  /* overflow-x: hidden; */
  font-family: "Poppins";
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}
</style>
