<template>
  <NavBarVue />
  <div class="main-container-div">
    <div class="brand-promotion-container">
      <div class="brand-promotion-heading">
        <h3>Promote Your Brand</h3>
        <h4>You make a <span>wish</span> we make it <span>happen!</span></h4>
      </div>
      <div class="brand-promotion-div">
        <div class="brand-promotion-img-div">
          <img src="./assets/hrms.png" alt="brand-img" />
          <h3>HRMS</h3>
        </div>
        <div class="brand-promotion-text">
          <h3 class="brand-heading">Brand Promotion</h3>
          <div class="brand-desc">
            <div class="left-red-line"></div>
            <p class="desc">
              Our campaigns will make your brand stand out and be recognized by
              thousands of people across numerous industries such as the IT,
              Marketing, F&B, Hospitality- industry, and so on.
            </p>
          </div>
          <h3 class="brand-price">Price : 25000/- per month</h3>
          <div class="social-media-container">
            <div class="social-media-div">
              <div class="social-media-img">
                <img src="./assets/link-din-logo.png" alt="social-media-img" />
              </div>
              <h3>Linkdin <span>(5000+ Followers)</span></h3>
            </div>
            <div class="social-media-div">
              <div class="social-media-img">
                <img src="./assets/facebook-logo.png" alt="social-media-img" />
              </div>
              <h3>Facebook <span>(70000+ Followers)</span></h3>
            </div>
            <div class="social-media-div">
              <div class="social-media-img">
                <img src="./assets/instagram-logo.jpg" alt="social-media-img" />
              </div>
              <h3>Instagram <span>(4000+ Followers)</span></h3>
            </div>
          </div>
          <div class="payment-button-div">
            <button type="button" @click="makePayment">Payment</button>
          </div>
        </div>
      </div>
    </div>

    <div class="main-container-div" id="brand-2">
      <div class="brand-promotion-container">
        <div class="brand-promotion-div">
          <div class="brand-promotion-img-div">
            <img src="./assets/hrms.png" alt="brand-img" />
            <h3>HRMS</h3>
          </div>
          <div class="brand-promotion-text">
            <h3 class="brand-heading">Brand Promotion</h3>
            <div class="brand-desc">
              <div class="left-red-line"></div>
              <p class="desc">
                Our campaigns will make your brand stand out and be recognized
                by thousands of people across numerous industries such as the
                IT, Marketing, F&B, Hospitality- industry, and so on.
              </p>
            </div>
            <h3 class="brand-price">Price : 25000/- per month</h3>
            <div class="social-media-container">
              <div class="social-media-div">
                <div class="social-media-img">
                  <img
                    src="./assets/link-din-logo.png"
                    alt="social-media-img"
                  />
                </div>
                <h3>Linkdin <span>(5000+ Followers)</span></h3>
              </div>
              <div class="social-media-div">
                <div class="social-media-img">
                  <img
                    src="./assets/facebook-logo.png"
                    alt="social-media-img"
                  />
                </div>
                <h3>Facebook <span>(70000+ Followers)</span></h3>
              </div>
              <div class="social-media-div">
                <div class="social-media-img">
                  <img
                    src="./assets/instagram-logo.jpg"
                    alt="social-media-img"
                  />
                </div>
                <h3>Instagram <span>(4000+ Followers)</span></h3>
              </div>
            </div>
            <div class="payment-button-div">
              <button type="button" @click="makePayment">Payment</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterVue />
</template>

<script>
import "./style/PromotionPage.css";

import FooterVue from "../Footer/Footer.vue";
import NavBarVue from "../NavBar/NavBar.vue";

export default {
  name: "PromotionPage",
  components: {
    NavBarVue,
    FooterVue,
  },
  methods: {
    makePayment() {
      this.$router.push("/paymentPage");
    },
  },
};
</script>

<style></style>