<template>
  <NavBarVue />
  <div class="main-div position-relative">
    <div class="width-100 position-relative header-section-bg">
      <div class="width-100 position-relative width-max margin-x-auto">
        <img
          src="./assets/Group44528.png"
          alt="Group44527"
          class="width-100 height-35 object-fit-fill header-background-img"
        />

        <div class="absolute top-5 left-12 custom-top-left">
          <div class="width-100">
            <img
              src="./assets/Group682x.png"
              alt="Group682x"
              class="width-10 custom-image-width"
            />
          </div>

          <div
            class="display-flex flex-column width-100 margin-top-2 header-desc"
          >
            <h4
              class="text-c-w margin-0 padding-0 font-weight-400"
              style="font-size: 2.125rem; line-height: 2.5rem"
            >
              ELEVATE YOUR BLOGGING <br />
              EXPERIENCE
            </h4>

            <p
              class="text-c-w text-smaller width-50 font-weight-400 custom-p-width"
            >
              Step into a new realm of blogging excellence with SliderSpectra.
              Our revolutionary slider designs are crafted to elevate your
              content, captivate your audience, and transform your blog into an
              immersive journey. Say goodbye to ordinary visuals and hello to
              extraordinary storytelling. Experience the power of SliderSpectra
              and take your blogging to new heights.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="absolute top-5 left-12 custom-top-left">
      <div class="width-100">
        <img
          src="./assets/Group682x.png"
          alt="Group682x"
          class="width-10 custom-image-width"
        />
      </div>

      <div class="display-flex flex-column width-100 margin-top-2">
        <h4
          class="text-c-w margin-0 padding-0 font-weight-400"
          style="font-size: 2.125rem; line-height: 2.5rem"
        >
          ELEVATE YOUR BLOGGING <br />
          EXPERIENCE
        </h4>

        <p
          class="text-c-w text-smaller width-50 font-weight-400 custom-p-width"
        >
          Step into a new realm of blogging excellence with SliderSpectra. Our
          revolutionary slider designs are crafted to elevate your content,
          captivate your audience, and transform your blog into an immersive
          journey. Say goodbye to ordinary visuals and hello to extraordinary
          storytelling. Experience the power of SliderSpectra and take your
          blogging to new heights.
        </p>
      </div>
    </div> -->

    <div
      class="display-flex justify-content-center align-items-center flex-column width-100 width-max margin-x-auto"
    >
      <!-- <div class="display-flex align-items-start flex-column width-75">
      <div class="display-flex gap-3 align-items-center">
        <h6 class="margin-0 padding-0 text-1xl font-400">Blog</h6>
        <div style="width: 10px; height: 10px; background-color: black; border-radius: 50%;"></div>
        <h6 class="margin-0 padding-0 font-bold">Artificial Intelligence</h6>
      </div>

      <h1 class="text-2xl text-c-b font-weight-bold margin-0 padding-0 width-75" style="line-height: inherit;">
        Navigating The AI Frontier :
        A Journey <br /> Into The World Of Artificial Intelligence
      </h1>
    </div>

    <div class="display-flex align-items-start flex-column width-75">
      <div class="display-flex gap-3 margin-top-4 margin-bottom-1">
        <div>
          <img src="./AssetsIBTalks/Group682x.png" alt="Group682x">
        </div>

        <div class="display-flex flex-column">
          <h6 class="margin-0 padding-0">User Name</h6>
          <div class="display-flex align-items-center gap-2">
            <p class="margin-0 padding-0">Post March 09, 2024</p>
            <div style="width: 10px; height: 10px; background-color: black; border-radius: 50%;"></div>
            <p class="margin-0 padding-0">5 Minute ago</p>
          </div>
        </div>
      </div>

      <div class="display-flex gap-3 align-items-center">
        <img src="./AssetsIBTalks/artificial-intelligence-new.png" alt="artificial-intelligence-new"
          class="width-100">
      </div>

      <p class="text-small text-c-b font-weight-400 margin-y-1 padding-0 width-75" style="line-height: inherit;">
        Artificial Intelligence (AI) has become an integral part of our lives, shaping industries,
        revolutionizing technology, and influencing societal norms. In this journey, we delve deep into the
        vast landscape of AI, exploring its origins, applications, ethical considerations, and future
        prospects.
      </p>

      <div class="">
        <h4 class="text-x-larger font-weight-bold">Understanding Artificial Intelligence: </h4>
        <p class="text-smaller">AI, in its various forms, encompasses the simulation of
          human intelligence processes by machines. From narrow AI, which is task-specific, to the elusive
          goal of achieving general AI, which can perform any intellectual task that a human can, the
          spectrum is broad. Within this realm lie machine learning, deep learning, and other
          sophisticated techniques that enable computers to learn from data and make decisions.</p>
      </div>

      <div class="">
        <h4 class="text-x-larger font-weight-bold">Historical Perspectives: </h4>
        <p class="text-smaller">Tracing back to its inception, AI has undergone a
          fascinating evolution. From the theoretical concepts proposed by early visionaries to the
          groundbreaking developments in the 21st century, such as IBM's Deep Blue defeating chess
          grandmaster Garry Kasparov, AI's journey is marked by significant milestones that have shaped
          its trajectory.</p>
      </div>

      <div class="">
        <h4 class="text-x-larger font-weight-bold">Real-world Applications: </h4>
        <p class="text-smaller">The practical applications of AI are boundless. In
          healthcare, AI aids in diagnosis, personalized treatment plans, and drug discovery. Financial
          institutions utilize AI for fraud detection, risk assessment, and algorithmic trading.
          Transportation sees advancements with self-driving cars and predictive maintenance in aviation.
          These examples merely scratch the surface of AI's potential to transform industries and enhance
          human capabilities.</p>
      </div>

      <div class="">
        <h4 class="text-x-larger font-weight-bold">Ethical Considerations: </h4>
        <p class="text-smaller">As AI permeates every aspect of our lives, ethical
          considerations come to the forefront. Issues surrounding privacy, bias in algorithms, and the
          societal impact of automation raise pertinent questions about the ethical use of AI. It's
          imperative to navigate these complexities with caution and conscientiousness.</p>
      </div>

      <div class="">
        <h4 class="text-x-larger font-weight-bold">The Future of AI: </h4>
        <p class="text-smaller">Looking ahead, the future of AI holds immense promise.
          Advancements in AI research, coupled with the proliferation of data and computing power, pave
          the way for transformative innovations. From enhancing human capabilities to addressing global
          challenges, AI has the potential to usher in a new era of progress and prosperity.</p>
      </div>
    </div>

    <div class="display-flex justify-content-center align-items-center gap-4 width-75 margin-y-2">
      <button class="text-c-w padding-2 display-flex align-items-center justify-content-space-evenly"
        style="background: #012A71 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000029; cursor: pointer; width: 8em;">Read
        More <img src="./AssetsIBTalks/Icon-awesome-angle-right.svg" alt="Icon-awesome-angle-right" width="10">
      </button>
    </div> -->

      <div class="heading-sec">
        <h3 class="">Blogs</h3>
        <h4 class="">OpenThe Gateway To Infinite Podcast Possibilities</h4>
      </div>

      <!-- <div class="display-flex justify-content-center align-items-center"> -->
      <div class="blog-container margin-top-2 width-100">
        <div v-for="blog in allBlogs" :key="blog.id" class="blog-card">
          <img
            src="./assets/artificial-intelligence-new.png"
            class=""
            alt="image"
          />
          <div class="blog-card-body">
            <h5 class="">{{ blog.title }}</h5>
            <!-- <span class="">Posted by, {{ blog.blogger_name }}</span> -->
          </div>
          <div class="blog-card-footer">
            <div class="blog-card-details">
              <h5>{{ blog.blogger_name }}</h5>
              <p>
                Posted on {{ formateDate(blog.posted_date) }}
                <i class="fa-solid fa-circle"></i>
                {{ formatTimeSince(blog.posted_date) }}
              </p>
              <p class="margin-0" style="margin-top: -0.18rem">
                {{ blog.like }} Peoples Likes This
                <i class="fa-solid fa-circle"></i>
                {{ blog.share }} Peoples Shares This
              </p>
            </div>
            <div class="blog-card-dot-btn">
              <i
                class="fa-solid fa-ellipsis"
                @click="openListFunction(blog.id)"
              ></i>
              <div
                class="blog-card-dot-list"
                :class="{ 'blog-card-dot-btn-active': openList }"
                v-if="selectedBlogId == blog.id"
              >
                <div class="list-icon display-count" @click="blogLike(blog.id)">
                  <i
                    v-if="isLiked && likedBlogId == blog.id"
                    id="active-heart"
                    class="fa-regular fa-heart"
                  ></i>
                  <i v-else class="fa-regular fa-heart"></i>
                  <!-- <p>{{ blog.like }}</p> -->
                </div>
                <!-- @click="shareBlog(blog.id)" -->
                <div
                  class="list-icon display-count"
                  @click="openShareListFunction()"
                >
                  <i
                    class="fa-solid fa-share-nodes"
                    :class="{ 'active-share-icon': openShareList }"
                  ></i>
                  <!-- <p>{{ blog.share }}</p> -->
                </div>
                <!-- :class="{ 'share-btn-active': openShareList }" -->
                <!-- v-if="selectedShareBlogId == blog.id" -->
                <div
                  class="share-list"
                  :class="{ 'share-list-active': openShareList }"
                >
                  <div class="list-icon">
                    <i
                      class="fa-brands fa-whatsapp"
                      id="whatsapp-icon"
                      @click="
                        shareBlog(blog.id).then(() => {
                          whatsappShare();
                        })
                      "
                    ></i>
                  </div>
                  <div class="list-icon">
                    <i
                      class="fa-brands fa-facebook"
                      id="facebook-icon"
                      @click="
                        shareBlog(blog.id).then(() => {
                          facebookShare();
                        })
                      "
                    ></i>
                  </div>
                  <div class="list-icon">
                    <i
                      class="fa-brands fa-twitter"
                      id="twitter-icon"
                      @click="
                        shareBlog(blog.id).then(() => {
                          twitterShare();
                        })
                      "
                    ></i>
                  </div>
                  <div class="list-icon">
                    <i
                      class="fa-brands fa-linkedin"
                      id="linkedin-icon"
                      @click="
                        shareBlog(blog.id).then(() => {
                          linkedinShare();
                        })
                      "
                    ></i>
                  </div>
                </div>
                <div class="list-icon" @click="readBlog(blog.id)">
                  <i class="fa-brands fa-readme"></i>
                </div>
              </div>
            </div>
            <!-- <button type="button" @click="readBlog(blog.id)">Read More</button> -->
          </div>
        </div>

        <div v-if="allBlogs.length <= 0" class="empty-blogs-div">
          <h5>Blogs will be coming soon...</h5>
        </div>
        <!-- <div class="blog-card">
        <img
          src="./AssetsIBTalks/artificial-intelligence-new.png"
          class="width-100"
          alt="image"
        />
        <div class="blog-card-body">
          <h5 class="">Blog Title</h5>
          <span class="">Posted by, Abhinav Singh</span>
        </div>
        <div class="blog-card-footer">
          <button type="button">Read More</button>
        </div>
      </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
  <FooterVue />
</template>
  
  <script>
import "./style/Custom_Style.css";
import "./style/IB_Talks_And_Blogs.css";

import FooterVue from "../Footer/Footer.vue";
import NavBarVue from "../NavBar/NavBar.vue";

import axios from "axios";

export default {
  name: "All_Blogs",
  data() {
    return {
      dividerColor: "2px solid gray",
      allBlogs: [],
      openList: false,
      selectedBlogId: "",
      isLiked: false,
      likedBlogId: "",
      likedIds: [],
      // base_url: "https://uatpodcast.infinitybrains.com/public/api/", // Uat Url
      base_url: "https://podcast.infinitybrains.com/public/api/", // Prod Url
      selectedShareBlogId: "",
      openShareList: false,
      redirect: false,
    };
  },
  components: {
    NavBarVue,
    FooterVue,
  },
  methods: {
    openListFunction(id) {
      this.openList = !this.openList;
      if (this.openShareList) {
        this.openShareList = false;
      }
      this.selectedBlogId = id;
      this.checkBlogLikes(id);
    },
    openShareListFunction(id) {
      this.openShareList = !this.openShareList;
      // console.log("Open List Flag: ", this.openShareList);
      this.selectedShareBlogId = id;
      // this.checkBlogLikes(id);
    },
    shareBlog(id) {
      try {
        return axios.post(`${this.base_url}share/${id}`).then((response) => {
          // console.log("Blog Share Response: ", response.data);
          this.copyUrlToClipboard(response.data.data.share_url);
          this.redirect = true;
          return true;
        });
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    whatsappShare() {
      if (this.redirect) {
        // console.log("Whatsapp Redirected!!");

        const whatsappUrl = "https://web.whatsapp.com/";
        window.open(whatsappUrl, "_blank");
      }
    },
    facebookShare() {
      if (this.redirect) {
        // console.log("Facebook Redirected!!");

        const facebookUrl = "https://www.facebook.com/";
        window.open(facebookUrl, "_blank");
      }
    },
    twitterShare() {
      if (this.redirect) {
        // console.log("Twitter Redirected!!");

        const twitterUrl = "https://twitter.com/";
        window.open(twitterUrl, "_blank");
      }
    },
    linkedinShare() {
      if (this.redirect) {
        // console.log("Linkedin Redirected!!");

        const twitterUrl = "https://in.linkedin.com/";
        window.open(twitterUrl, "_blank");
      }
    },
    copyUrlToClipboard(url) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          // alert("Blog Shared Successfully !!");
          // console.log("URL copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy URL:", err);
        });
    },
    blogLike(id) {
      this.isLiked = !this.likedIds.includes(id); // Toggle based on presence
      this.likedBlogId = id;

      if (this.isLiked) {
        try {
          axios.post(`${this.base_url}like/${id}`).then(() => {
            // console.log("Blog Like Response: ", response.data);
          });
        } catch (err) {
          console.error(err);
        }
        this.likedIds.push(id);
      } else {
        const index = this.likedIds.indexOf(id);
        if (index > -1) {
          try {
            axios.post(`${this.base_url}dislike/${id}`).then(() => {
              //   console.log("Blog Dislike Response: ", response.data);
            });
          } catch (err) {
            console.error(err);
          }
          this.likedIds.splice(index, 1);
        }
      }

      // this.isLiked = true;
      // this.likedBlogId = id;

      // // Check if the ID already exists in the array to avoid duplicates
      // if (!this.likedIds.includes(id)) {
      //   this.likedIds.push(id); // Add the new ID to the array
      // } else {
      //   const index = this.likedIds.indexOf(id); // Find the index of the ID
      //   if (index > -1) {
      //     this.likedIds.splice(index, 1); // Remove the element at the found index (remove 1 element)
      //   }
      // }

      // Store the updated array of liked IDs in localStorage
      localStorage.setItem("likedIds", JSON.stringify(this.likedIds));
    },
    checkBlogLikes(id) {
      // console.log("Liked Ids: ", this.likedIds);
      this.likedIds.forEach((likeId) => {
        if (likeId == id) {
          this.isLiked = true;
          this.likedBlogId = id;
          return;
        }
      });
    },
    changeDividerColor(isHovered) {
      if (isHovered) {
        this.dividerColor = "2px solid black";
      } else {
        this.dividerColor = "2px solid gray";
      }
    },
    formateDate(dateString) {
      // Create a new Date object from the provided string
      const date = new Date(dateString);

      // Get month, day, and year components
      // eslint-disable-next-line no-unused-vars
      const month = date.getMonth() + 1; // Months are zero-indexed (January = 0)
      const day = date.getDate();
      const year = date.getFullYear();

      // Format the date in the desired format (April 08, 2024)
      return `${new Intl.DateTimeFormat("en-US", { month: "long" }).format(
        date
      )} ${day}, ${year}`;
    },
    formatTimeSince(dateString) {
      // Create Date objects for the past date and current time
      const pastDate = new Date(dateString);
      const now = new Date();

      // Calculate the difference in milliseconds
      const difference = now.getTime() - pastDate.getTime();

      // Define time units in milliseconds
      const secondsInMinute = 60 * 1000;
      const minutesInHour = 60 * secondsInMinute;
      const hoursInDay = 24 * minutesInHour;
      const daysInYear = 365 * hoursInDay; // Approximate value (ignores leap years)

      // Calculate years, months, days, hours, minutes, and seconds
      const years = Math.floor(difference / daysInYear);
      const months = Math.floor((difference % daysInYear) / (daysInYear / 12)); // Approximate months (ignores leap years)
      const days = Math.floor((difference % daysInYear) / hoursInDay);
      const hours = Math.floor((difference % hoursInDay) / minutesInHour);
      const minutes = Math.floor(
        (difference % minutesInHour) / secondsInMinute
      );
      const seconds = Math.floor((difference % secondsInMinute) / 1000);

      // Define a function to format the time string with pluralization
      const formatTime = (value, unit) => {
        return `${value} ${unit}${value > 1 ? "s Ago" : " Ago"}`;
      };

      // Choose the appropriate time unit based on the difference
      if (years > 0) {
        return formatTime(years, "year");
      } else if (months > 0) {
        return formatTime(months, "month");
      } else if (days > 0) {
        return formatTime(days, "day");
      } else if (hours > 0) {
        return formatTime(hours, "hour");
      } else if (minutes > 0) {
        return formatTime(minutes, "minute");
      } else if (seconds > 5) {
        // Display seconds only if greater than 5
        return formatTime(seconds, "second");
      } else {
        return "Just Posted";
      }
    },
    getAllBlogs() {
      try {
        axios.get(`${this.base_url}readall?order=desc`).then((response) => {
          // console.log("Response of all blogs: ", response);
          this.allBlogs = response.data.data;
          // console.log("All Blogs Array: ", this.allBlogs);
        });
      } catch (err) {
        console.error(err);
      }
    },
    readBlog(id) {
      this.$router.push({
        name: "Blogs",
        params: {
          id: id,
        },
      });
    },
  },
  created() {
    // Get existing liked IDs from localStorage (initialize as empty array if not set)
    this.likedIds = JSON.parse(localStorage.getItem("likedIds")) || [];
    // console.log("Likes Ids: ", this.likedIds);
  },
  mounted() {
    this.getAllBlogs();
  },
};
</script>
  
<style scoped>
.main-div {
  top: 0rem;
  margin-bottom: 5rem;
}

@media (max-width: 767px) {
  .custom-top-left {
    top: 6rem;
    left: 1rem !important;
    width: 100%;
    padding-left: 2rem;
  }

  .custom-image-width {
    width: 20%;
  }
}

@media (max-width: 1024px) {
  .main-div {
    top: 0rem;
  }
  .header-background-img {
    height: 35rem;
  }

  .custom-top-left {
    top: 5rem;
    left: 4rem;
    padding-left: 2rem;
  }
}

@media (max-width: 450px) {
  .header-background-img {
    height: 38rem;
  }

  .custom-top-left {
    top: 2rem;
    left: 0rem !important;
    padding-left: 2rem;
  }

  .custom-p-width {
    width: 80%;
  }

  .custom-logo-img {
    width: 20%;
  }
}

@media only screen and (min-width: 1628px) {
  .header-section-bg {
    background-image: url("./assets/Group44528.png");
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: fill;
    height: 40rem;
  }
  .header-background-img {
    display: none;
    height: 42rem;
  }
  .custom-top-left {
    top: 8rem;
  }

  .header-desc h4 {
    font-size: 2.8rem !important;
  }

  .header-desc p {
    font-size: 1.1rem !important;
  }
}
</style>