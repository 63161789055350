<template>
  <NavBarVue />
  <div class="about-us-container">
    <div class="about-us-heading">
      <h3>About <span>Us</span></h3>
      <h4>You make a <span>wish</span> we make it <span>happen!</span></h4>
    </div>

    <div class="about-us-section-1">
      <div class="about-us-img-div">
        <img src="./assets/Group_4009.png" alt="about-us-img" />
      </div>
      <div class="about-us-desc-div">
        <h3>
          Embody sophistication and class in every aspect of your online
          presence with the help of our dynamic team of creative and relentless
          powerhouses.
        </h3>
        <h4>Convergence solutions from scratch to hatch!</h4>
      </div>
    </div>

    <div class="about-us-center-text">
      <h3>
        You can rest assured that your brand's pure vitality will be preserved
        without going against its nature.
      </h3>
    </div>

    <div class="about-us-section-bg">
      <div class="about-us-section-2" id="section-2">
        <div class="section-2-text">
          <h3>Make sure your marketing gets its well-deserved rocket ride.</h3>
        </div>
        <div class="section-2-img">
          <img src="./assets/Group_4537.png" alt="about-us-img" />
        </div>
      </div>
    </div>

    <div class="about-us-section-3">
      <div class="about-us-heading">
        <h3>The Story of <span>Innovation & Perception!</span></h3>
      </div>

      <div class="section-3-main-div">
        <div class="section-3-img-div">
          <img src="./assets/Group_4557.png" alt="section-3-img" />
        </div>
        <div class="section-3-desc">
          <h3>
            With years or experience in creation & development, once you're part
            of our family, You're special whatever you wish for we make it
            happen,
          </h3>
          <h4>Easily, Especially, and Economically*.</h4>
        </div>
      </div>
    </div>

    <div class="about-us-section-bg">
      <div class="about-us-section-4" id="section-2">
        <div class="about-us-heading">
          <h3>Your <span>Vision</span> to <span>Reality!</span></h3>
        </div>
        <div class="section-4-main-div">
          <div class="section-4-desc">
            <h3>What do we thrive for?</h3>
            <h4>
              The pursuit of our company is to become the world's best company,
              one that challenges us constantly and never loses its enthusiasm.
            </h4>
          </div>
          <div class="section-4-img-div">
            <img src="./assets/Group_3140.png" alt="section-4-img" />
          </div>
        </div>
      </div>
    </div>

    <div class="about-us-center-text-2">
      <h3>
        We’ll add a whole new dimension of growth and achievement to your brand.
      </h3>
    </div>

    <div class="about-us-section-5">
      <div class="about-us-heading">
        <h3>Our Vision</h3>
      </div>

      <div class="section-5-main-div">
        <div class="section-5-img-div">
          <img src="./assets/Group_4567.png" alt="section-5-img" />
        </div>
        <div class="section-5-desc">
          <h3>
            It all started with the vision to develop and implement integrated
            marketing strategies to sweeten the presence and performance of
            small & medium brands and enhance the content of generous brands &
            young talent online.
          </h3>
        </div>
      </div>
    </div>

    <div class="about-us-section-bg">
      <div class="about-us-section-6" id="section-2">
        <div class="section-6-img-div">
          <img src="./assets/CTA.png" alt="section-6-img" />
        </div>
        <div class="section-6-heading">
          <h3>FEEL FREE TO REACH US:</h3>
        </div>
        <div class="section-6-desc">
          <h3>
            Feel free to reach out to us and share your company's vision so that
            we can turn it into a reality.
          </h3>
        </div>
        <div class="section-6-buttons-div">
          <button type="button" @click="this.$router.push('/contactus')">
            <div class="button-content">
              <img src="./assets/icon_1.png" alt="icon-1-img" />
              <h3>BOOK A CALL</h3>
            </div>
          </button>
          <button type="button" @click="this.$router.push('/contactus')">
            <div class="button-content">
              <img src="./assets/icon_2.png" alt="icon-1-img" id="icon-2" />
              <h3>EMAIL US</h3>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <FooterVue />
</template>

<script>
import "./style/AboutUsPage.css";

import FooterVue from "../Footer/Footer.vue";
import NavBarVue from "../NavBar/NavBar.vue";

export default {
  name: "AboutUsPage",
  components: {
    NavBarVue,
    FooterVue,
  },
};
</script>

<style></style>
